import React from 'react';
import { useLanguage } from '@app/hooks/useLanguage';
import * as S from './LanguagePicker.styles';
import ReactCountryFlag from 'react-country-flag';

export const LanguagePicker: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <S.ButtonGroup $isFirstActive={language == 'en'}>
      <S.Btn
        size={'small'}
        type={'link'}
        icon={<ReactCountryFlag svg countryCode="GB" />}
        onClick={() => setLanguage('en')}
      />
      <S.Btn
        size={'small'}
        type={'link'}
        icon={<ReactCountryFlag svg countryCode="DE" />}
        onClick={() => setLanguage('de')}
      />
    </S.ButtonGroup>
  );
};
