import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import { ThemePicker } from '../ThemePicker/ThemePicker';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useAppSelector } from '@app/hooks/reduxHooks';
import * as S from './SettingsOverlay.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, List, Row } from 'antd';
// import { NavigationToggle } from '../navigationToggle/NavigationToggle';

export const SettingsOverlay: React.FC = () => {
  const { t } = useTranslation();

  const { isPWASupported, event } = useAppSelector((state) => state.pwa);

  interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
      outcome: 'accepted' | 'dismissed';
      platform: string;
    }>;
    prompt(): Promise<void>;
  }

  return (
    <BaseButtonsForm.Item
      style={{
        padding: 20,
      }}
    >
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <Col xs={24} lg={12} style={{ marginBottom: 20 }}>
          <List>
            <List.Item>
              <BaseButtonsForm.Title style={{ fontSize: '1rem', marginBottom: 0 }}>
                {t('header.changeLanguage')}
              </BaseButtonsForm.Title>
              <LanguagePicker />
            </List.Item>
            <List.Item>
              <BaseButtonsForm.Title style={{ fontSize: '1rem', marginBottom: 0 }}>
                {t('header.changeTheme')}
              </BaseButtonsForm.Title>
              <ThemePicker />
            </List.Item>
          </List>
        </Col>
        {isPWASupported && (
          <Col xs={24} lg={12} style={{ marginBottom: 20 }}>
            <Col xs={24} lg={12} style={{ marginBottom: 20 }}>
              <S.PwaInstallWrapper>
                <Button
                  block
                  type="primary"
                  onClick={() => event && (event as unknown as BeforeInstallPromptEvent).prompt()}
                >
                  {t('common.pwa')}
                </Button>
              </S.PwaInstallWrapper>
            </Col>
          </Col>
        )}
      </Row>
    </BaseButtonsForm.Item>
  );
};
