import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

interface NavToggleState {
  isCollapsed: boolean;
}

const isCollapsed = localStorage.getItem('isCollapsed') === 'true';

localStorage.setItem('navToggle', JSON.stringify(isCollapsed));

const initialState: NavToggleState = {
  isCollapsed,
};

export const setIsCollapsed = createAction<PrepareAction<boolean>>('navToggle/isCollapsed', (isCollapsed) => {
  localStorage.setItem('navToggle', JSON.stringify(isCollapsed));

  return {
    payload: isCollapsed,
  };
});

export const navToggleSlice = createSlice({
  name: 'navToggle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setIsCollapsed, (state, action) => {
      state.isCollapsed = action.payload;
    });
  },
});

export default navToggleSlice.reducer;
